const runDevAsProd = false;
const settings = {
    testNet: (process.env.NODE_ENV === 'development' && !runDevAsProd)?true:false, //Change to false for production
    useDevServer: (process.env.NODE_ENV === 'development' && !runDevAsProd)?true:false //Change to false for using production servers
}

const appConfig = {
    version: '1.1.8',
    appLink: 'https://app.wordofmine.io/',
    websiteLink: 'https://www.wordofmine.io',
    testnet: settings.testNet,
    walletConnectKey: '8c30a46fd1d4017c1732255603f8ee40',
    customRPCURL: settings.testNet?'https://bold-misty-thunder.bsc-testnet.quiknode.pro/2e47f29249ec2044358b04e737e2438b16a753ea/':'https://quaint-alien-valley.bsc.quiknode.pro/4e97660451fc9060ebd399536f8d3e0925cb414f/', //admin@spicydevs.com
    apiEndpoint: settings.useDevServer?'https://dev.wordofmine.io/app/':'https://api.wordofmine.io/app/',
    supportedChain: settings.testNet?'97':'56',
    supportedChainName: settings.testNet?'Binance Smart Chain Testnet':'Binance Smart Chain Mainnet',
    scanURL: settings.testNet?'https://testnet.bscscan.com/':'https://bscscan.com/',
    gaSiteCode: "G-CTDVL8W4LS",

    //URLs
    complan: 'https://www.wordofmine.io/complan.pdf',
    complanVideo: 'https://t.me/wordOfMineID1Alerts/10926',
    support: 'https://wordofmine.freshdesk.com/',
    telegram: 'https://t.me/wordofmineio',
    youtube: 'https://www.youtube.com/watch?v=njh4A2tig1M&t=3s',
    ytChannel: 'https://www.youtube.com/@wordofmineofficial',
    id1BuyAlertGroup: 'https://t.me/wordOfMineID1Alerts'
}

export default appConfig;