import packsIcon from "../../../Assets/packsPurchased.png";
import iTotalIncome from "../../../Assets/total-income.png";
import usersIcon from "../../../Assets/usersIcon.png";
import loader from "../../../Assets/loader.png";
import { useUserAuth } from "../../../Contexts/authContext";
import { BlkContainer } from "../../../Utility/Utility";
import Stats from "../dashboard/Stats";

export const FooterStats = () => {

    const auth = useUserAuth();

    return (
        <BlkContainer className='bg-3 mt-7 max-w-5xl p-7 ml-auto'>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 justify-center items-center align-middle gap-5'>
                <Stats
                    value={auth?.userBootstrap?.siteStats?.users}
                    label='Total Users'
                    icon={usersIcon}
                />
                {/* <Stats
                    value={auth?.userBootstrap?.siteStats?.todayUsers}
                    label='Users Registered Today'
                    icon={usersIcon}
                /> */}
                <Stats
                    value={auth?.userBootstrap?.siteStats?.packsPurchased}
                    label='Pickaxes Purchased'
                    icon={packsIcon}
                />
                {/* <Stats
                    value={auth?.userBootstrap?.siteStats?.packsCycled}
                    label='Total Pickaxes generated by cycle'
                    icon={packsIcon}
                /> */}
                <Stats
                    value={auth?.userBootstrap?.siteStats?.packToCycle}
                    label='Pickaxe being cycled'
                    icon={loader}
                />
                {/* <Stats
                    value={auth?.userBootstrap?.siteStats?.todayPacks}
                    label='Pickaxes Purchased Today'
                    icon={packsIcon}
                /> */}
                <Stats
                    value={'$' + auth?.userBootstrap?.siteStats?.earned}
                    label='Total Earnings by Users'
                    icon={iTotalIncome}
                />
                {/* <Stats
                    value={'$' + auth?.userBootstrap?.siteStats?.earnedToday}
                    label='Earned By Users Today'
                    icon={iTotalIncome}
                /> */}
            </div>
        </BlkContainer>
    );
}