import bgDashboard from "../Assets/bg-dashboard.webp";
import gear from "../Assets/gear.png";

export default function LayoutLoading() {  
    return (
        <div className='bg-[#0B002B] relative font-khand px-4 pb-4 md:p-6 pt-4'>
            <div
                className='w-full h-full absolute top-0 start-0 bottom-0 end-0 bg-cover bg-center z-0'
                style={{ backgroundImage: `url(${bgDashboard})`, backgroundPosition: 'center bottom', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}
            ></div>
            <div className="grid h-screen place-items-center bg-[#0B002B]">
                <img src={gear} className={`w-[60px] animate-spin`} />
            </div>
        </div>
    );
}