import React from "react";
import { Link } from "react-router-dom";
import btnCreateaccount from "../../Assets/btn-createaccount.png";
import btnLogin from "../../Assets/btn-login.png";
import logo from "../../Assets/logo.png";

export const WelcomeBox = ({ layout = 'login' }) => {
    return (
    <div className='text-center mb-9 lg:mb-0'>
        <img
        className='w-[200px] lg:w-[300px] mx-auto mb-6'
        src={logo}
        alt='Logo'
        />
        {layout === 'login' ? 
            <>
                <p className='text-white font-semibold text-base lg:text-lg mb-2 text-center'>
                    New here?
                </p>
                <Link to={'/register'}>
                    <button className='h-[120px] inline-block'>
                        <img
                            className='max-w-full max-h-full block'
                            src={btnCreateaccount}
                            alt='btn'
                        />
                    </button>
                </Link>
            </>
        :
            <>
                <p className='text-white font-semibold text-base lg:text-lg mb-2 text-center'>
                    Already Registered?
                </p>
                <Link to={'/login'}>
                    <button className='h-[110px] inline-block'>
                        <img
                            className='max-w-full max-h-full block'
                            src={btnLogin}
                            alt='btn'
                        />
                    </button>
                </Link>
            </>
        }        
    </div>
    );
}
