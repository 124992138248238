import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import logo from "../../../Assets/logo.png";
import { Overlay } from "../../../Utility/Utility";
import Drawer from "./Drawer";
import UserDetailsDash from "./UserDetailsDash";

const HeaderDash = () => {

  const [drawer, setDrawer] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 1023 });

  const closeDrawer = () => {
    setDrawer(false);
  };

  return (
    <header className='bg-transparent pr-3 pl-2 md:px-5'>
      <Overlay overlay={drawer && isSmallScreen} />
      <div className='flex items-center justify-between relative z-50'>
        <Link className='-mb-8'>
          <img
            src={logo}
            alt='logo'
            className='max-w-[100px] md:max-h-[100px] xl:max-h-[140px] lg:max-w-[250px]'
          />
        </Link>
        <div className='hidden xl:block ml-auto space-y-3'>
          <div className='flex items-center justify-end space-x-5 xl:space-x-8'>
            <UserDetailsDash />
          </div>
        </div>
        <div
          className={`${
            drawer && isSmallScreen ? "translate-x-0" : "translate-x-full"
          } fixed h-screen w-[290px] bg-black right-0 top-0 z-50 p-6 transition duration-300 yScroll`}
        >
          <Drawer onCloseDrawer={closeDrawer} />
        </div>

        <button
          onClick={() => setDrawer(true)}
          className='mobile-bar ml-auto xl:hidden'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 24 24'
            fill='none'
            stroke='#FCDF8F'
            strokeWidth='2'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='w-9 h-9 transition duration-300'
          >
            <line x1='3' y1='12' x2='21' y2='12'></line>
            <line x1='3' y1='6' x2='21' y2='6'></line>
            <line x1='3' y1='18' x2='21' y2='18'></line>
          </svg>
        </button>
      </div>
    </header>
  );
};

export default HeaderDash;