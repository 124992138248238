import React, { useEffect, useState } from "react";
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from "../../Contexts/walletContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { BlkContainer, EmptyRecords, LoadingData, TitlePrimary, TitleSecondary } from "../../Utility/Utility";
import { useHelpers } from "../../Hooks/helpers";
import Timestamp from "react-timestamp";
import { Button, ButtonGroup, Card, Chip } from "@material-tailwind/react";
import { toast } from "react-hot-toast";
import CopyToClipboard from "react-copy-to-clipboard";
import { RefreshCw, X } from "react-feather";
import { ButtonTwo } from "../../Utility/Buttons";
import { Link } from "react-router-dom";
import appConfig from "../../Utility/Config/config";
import SetUsername from "../../Components/dash/dashboard/SetUsername";

const TelegramAlerts = () => {

    const auth = useUserAuth();
    const helper = useHelpers();
    const callAPI = useCallAPI();
    const walletContext = useWalletContext();

    const [tgData, setTgData] = useState(null);
    const [blockDiv, setBlockDiv] = useState('');

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
            getResults();
        }
    }, [auth, walletContext.walletConnected]);

    const getResults = async () => {
        setBlockDiv(true);
        const res = await callAPI("telegramUserData");       
        if(res.status && res.data) {
            setTgData(res.data);
        } else {
            setTgData(false);
        }
        setBlockDiv(false);
    }

    const generateSecretKey = async () => {
        setBlockDiv(true);
        const submitResponse = await callAPI("telegramRegenerateSecretKey");
        if(submitResponse.data.state === 1) { //Success
            getResults();
        } else { //Handle error
            toast.error(submitResponse.data.error);
        }
        setBlockDiv(false);
    }

    const disconnectBot = async () => {
        setBlockDiv(true);
        const submitResponse = await callAPI("telegramDeactivateAccount");
        if(submitResponse.data.state === 1) { //Success
            toast.error('Account disconnected');
            getResults();
        } else { //Handle error
            toast.error(submitResponse.data.error);
        }
        setBlockDiv(false);
    }

    return (
        <>
            <div className='pt-5 pb-10'>
                <TitlePrimary>Change Username</TitlePrimary>
            </div>
            <BlkContainer className="bg-2">
                <SetUsername />
            </BlkContainer>
            <div className='py-10'>
                <TitlePrimary>Official Telegram Group</TitlePrimary>
            </div>
            <BlkContainer className="bg-2 sm:flex items-center align-middle justify-between px-4 py-3 rounded-10 shadow-xl">
                <div className="">
                    <div className="text-2xl font-bold text-primary">JOIN OUR OFFICIAL TELEGRAM GROUP</div>
                    <div className="text-white text-lg">Stay upto date with Official Announcement</div>
                </div>
                <div className="mt-4 sm:mt-0">
                    <Link to={appConfig.telegram} target="blank"><Button color="amber" size="lg">Open Telegram</Button></Link>
                </div>
            </BlkContainer>
            <div className="my-10">
                <TitlePrimary>Sync Telegram for Alerts</TitlePrimary>
            </div>
            <div className="sm:grid grid-cols-3 space-y-7 sm:space-y-0 sm:space-x-7">
                <BlkContainer className="shadow-xl px-5 py-4 rounded-10">
                    <div className="text-2xl text-primary mb-4">Step 1 - Open Telegram Notifier Bot</div>
                    <div className="text-md text-gray-100 mb-6">Use the below buttons to open the {auth?.systemSettings?.PROJECT_NAME} Notifier Bot. Go to step 2 once the bot is opened in your telegram app.</div>
                    <div className="flex items-start justify-start space-x-2">
                        <a href={'https://t.me/' + auth?.systemSettings?.TELEGRAM_BOT_ACCOUNT} target="blank"><Button size="lg" color="indigo">Open Telegram Bot</Button></a>
                        <CopyToClipboard onCopy={() => toast.success('Copied!')} text={auth?.systemSettings?.TELEGRAM_BOT_ACCOUNT}><Button size="lg" color="green" >Copy bot username</Button></CopyToClipboard>
                    </div>
                </BlkContainer>
                <BlkContainer className="shadow-xl px-5 py-4 rounded-10">
                    <div className="text-2xl text-primary mb-4">Step 2 - Your Secret Key</div>
                    <div className="text-md text-gray-100 mb-6">Get your SECRET_KEY here which you will need to connect your telegram account with your {auth?.systemSettings?.PROJECT_NAME} account. Once generated, Go back to the telegram bot and paste the activation string to activate the bot.</div>
                    <div className="flex items-center justify-center space-x-2">
                        {tgData && tgData.secret_key && 
                            <ButtonGroup color="green" size="lg">
                                <CopyToClipboard onCopy={() => toast.success('Copied!')} text={"/activate " + tgData.secret_key}><Button>/activate {tgData.secret_key}</Button></CopyToClipboard>
                                <Button onClick={generateSecretKey} color="indigo"><RefreshCw size={15} /></Button>
                            </ButtonGroup>
                        }
                        {(!tgData || !tgData.secret_key) && <Button size="lg" color="indigo" onClick={generateSecretKey} >Generate Secret Key</Button>}
                    </div>
                </BlkContainer>
                <BlkContainer className="shadow-xl px-5 py-4 rounded-10">
                    <div className="text-2xl text-primary mb-4">Step 3 - Done</div>
                    <div className="text-md text-gray-100 mb-6">Once you have followed the steps correctly, your bot will be activated and you will start receiving notifications.</div>
                    <div className="flex items-center justify-center space-x-2">
                        {tgData && tgData.client_username && 
                            <ButtonGroup color="green">
                                <Button>Connected to {tgData.client_username}</Button>
                                <Button color="red" onClick={disconnectBot}><X size={15} /></Button>
                            </ButtonGroup>
                        }
                        {(!tgData || !tgData.client_username) && <Button size="sm" color="red" variant="outline">Not Connected</Button>}
                    </div>
                </BlkContainer>
            </div>
        </>
    );
};

export default TelegramAlerts;
