import { Button } from '@material-tailwind/react';
import { useWeb3Modal } from '@web3modal/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import titleimg from "../../Assets/LOGIN ACCOUNT.png";
import btnConnect from "../../Assets/btn-connectwallet.png";
import btnLogin from "../../Assets/btn-login.png";
import lock from "../../Assets/lock.png";
import { WelcomeBox } from '../../Components/auth/WelcomeBox';
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from '../../Contexts/walletContext';
import { useCallAPI } from '../../Hooks/callAPI';
import { useHelpers } from '../../Hooks/helpers';
import { useReadContract } from '../../Hooks/readContract';
import { useWriteContract } from '../../Hooks/writeContract';

const Login = ({ autoLogin = false }) => {
    const callAPI = useCallAPI();
    const history = useNavigate();
    const helpers = useHelpers();
    const authContext = useUserAuth();
    const walletContext = useWalletContext();
    const readContract = useReadContract();
    const writeContract = useWriteContract();
    const web3modal = useWeb3Modal();

    const [blockDiv, setBlockDiv] = useState('');

    //Check for authentication
    useEffect(() => {
      if(authContext.appBootstrapped === true && authContext.loggedIn === true) {
          history('/dashboard');
          return;
      }
    }, [authContext]);

    useEffect(() => {
      if(authContext.appBootstrapped === true && authContext.loggedIn === false && autoLogin) {
        loginNow();
        return;
      }
    }, [authContext, walletContext.walletConnected]);
    
    const loginNow = async () => {

        setBlockDiv('loginNow');
        try {
            //Check if the address is registered in the users contract
            const userRes = await readContract.read('isUserExists', [walletContext.userAddress]);
            if(userRes !== true) { throw 'Register first'; }

            const res = await callAPI("getLoginNonce", { address: walletContext.userAddress });
            if(res.data.state !== 1) { throw res.data.error };
            if(res.data.state === 1) { //Success
                //Sign message with nonce to login
                await writeContract.SignMessage(res.data.nonce, postLogin);
                setBlockDiv('');
            }
        } catch (error) {
            console.log(error);
            try {
              toast.error(error);
            } catch (error) {
              toast.error('Unexpected error occurred E001');
            }
            setBlockDiv('');
        }
        
    }

    const postLogin = async (signatureHash) => {
        //Send the hash signature to verify and login this user
        const loginRes = await callAPI("verifyLogin", { address: walletContext.userAddress, signature: signatureHash });
        if(loginRes.data.state !== 1) { toast.error(loginRes.data.error) }
        if(loginRes.data.state === 1) { //Success
            localStorage.setItem('userauthtoken', loginRes.data.auth_token);
            await authContext.getAppBootstrap();
            history('/');
        }
    }

    return (
      <>
        <WelcomeBox layout='login' />
        <div className='bg-one lgn-blk relative rounded-2xl_next border-[4px] border-[#F9F293] px-4 py-6 md:py-12 md:px-14'>
          <div className='space-y-6 pt-2'>
            <div className='h-[35px] lg:h-[45px] mb-7 lg:mb-10'>
              <img
                className='max-w-full max-h-full block mx-auto'
                src={titleimg}
                alt='title'
              />
            </div>
            <div className='text-center'>
              <p className='text-[#DFCA45] font-semibold text-xl md:text-[22px] mb-3'>
                1. Connect BSC Wallet
              </p>
              {walletContext.walletConnected ? 
                <Button onClick={web3modal.open} color='green'>Connected to : <helpers.trimAddress address={walletContext.userAddress} firstShow={5} secondShow={5}/></Button>
              : 
                <button onClick={walletContext.connectWallet} className='h-[120px] inline-block'>
                  <img
                    className='max-w-full max-h-full block'
                    src={btnConnect}
                    alt='btn'
                  />
                </button>
              }
            </div>
            <div className='text-center'>
              <p className='text-[#DFCA45] font-semibold text-xl md:text-[22px] mb-3'>
                2. Authenticate wallet
              </p>
              <button onClick={loginNow} disabled={!walletContext.walletConnected || blockDiv === 'loginNow'} className='h-[110px] inline-block disabled:opacity-50'>
                <img
                  className='max-w-full max-h-full block'
                  src={btnLogin}
                  alt='btn'
                />
              </button>
            </div>
          </div>
          <img
            className='absolute -left-10 -bottom-10 w-[60px] lg:w-[80px]'
            src={lock}
            alt='lock'
          />
        </div>
      </>
    );
};

export default Login;
