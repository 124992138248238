import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../../Contexts/authContext";
import { useWalletContext } from "../../../Contexts/walletContext";
import DashStatContent from "./DashStatContent";

const DashStatReferrals = ({ data }) => {

  const auth = useUserAuth();
  const walletContext = useWalletContext();

  const [stats, setStats] = useState([]);

  useEffect(() => {
    if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
        getStats();
    }
  }, [auth, walletContext.walletConnected]);

  const getStats = () => {
    setStats([
      {
        title: 'Total Mining Partners',
        value: data.userStats.totalReferrals,
      },
      {
        title: 'Mining Partners invited Today',
        value: data.userStats.referredToday,
      },
      {
        title: 'Gold Rush Payments Received',
        value: '$' + data.userStats.fastStartEarnings,
      },
      {
        title: 'Gold Rush Payments Received Today',
        value: '$' + data.userStats.fastStartEarningsToday,
      },
      {
        title: 'Gold Rush Payments Missed',
        value: '$' + data.userStats.missedFastStartEarnings,
      },
      {
        title: 'Gold Rush Payments Missed Today',
        value: '$' + data.userStats.missedFastStartEarningsToday,
      },
    ]);
  }
  
  return (
    <DashStatContent stats={stats} title={'My Referrals Stats'} />
  );
};
export default DashStatReferrals;
