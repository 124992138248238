import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AccountDetails from "../../Components/dash/dashboard/AccountDetails";
import CopyLink from "../../Components/dash/dashboard/CopyLink";
import DashStatPacks from "../../Components/dash/dashboard/DashStatPacks";
import DashStatReferrals from "../../Components/dash/dashboard/DashStatReferrals";
import DashStatUnilevel from "../../Components/dash/dashboard/DashStatUnilevel";
import PurchasePack from "../../Components/dash/dashboard/PurchasePack";
import UnilevelDownlines from "../../Components/dash/dashboard/UnlievelDownlines";
import Maintitle from "../../Components/dash/layout/MainTitle";
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from "../../Contexts/walletContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { BlkContainer, TitlePrimaryTwo } from "../../Utility/Utility";
import { Button } from "@material-tailwind/react";

const Dashboard = () => {

  const auth = useUserAuth();
  const history = useNavigate();
  const walletContext = useWalletContext();
  const callAPI = useCallAPI();

  const [stats, setStats] = useState(false);

  //Get user balance
  useEffect(() => {
      if(auth.appBootstrapped === true && auth.loggedIn === false) {
        history('/login');
        return;
      }
      if(walletContext.walletConnected === true) {
          getResults();
      }
  }, [auth, walletContext.walletConnected]);

  const getResults = async () => {
      const res = await callAPI("dashboard");      
      if(res.status && res.data) {
          setStats(res.data);
      } else {
          setStats([]);
      }
  }

  return (
    <>
      <Maintitle title='DASHBOARD' />
      <div className='space-y-7'>
        <div>
          <BlkContainer className='bg-1'>
            <PurchasePack />
          </BlkContainer>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-[40%_60%] lg:space-x-7 space-y-7 lg:space-y-0">
          <BlkContainer className='bg-1'>
            <UnilevelDownlines data={stats.unilevelStats} />
          </BlkContainer>
          <div>
            <BlkContainer className='bg-2'>
              <div className='text-center p-4 pt-6 lg:p-6 space-y-8 lg:pt-8'>
                <div className="flex items-center justify-between align-middle">
                <TitlePrimaryTwo>SHARE YOUR LINK</TitlePrimaryTwo>
                <Link to={'/tgAlerts'}><Button color="white">Edit Username</Button></Link>
                </div>
                <div className='space-y-4 2xl:space-y-0 2xl:grid grid-cols-2 gap-6 p-6 bg-[rgba(24,22,22,0.70)] rounded-2xl_next box-shadow-[0px_11px_10px_0px_rgba(0,0,0,0.10)]'>
                  <CopyLink
                    title='Link to Register page'
                    link={auth?.userBootstrap?.userData?.userRefLinkReg}
                  />
                  <CopyLink
                    title='Link to Home page'
                    link={auth?.userBootstrap?.userData?.userRefLinkHome}
                  />
                </div>
              </div>
            </BlkContainer>
            <BlkContainer className='bg-2 mt-7'>
              <AccountDetails />
            </BlkContainer>
          </div>
        </div>
        {stats && 
          <div className="grid grid-cols-1 lg:grid-cols-3 lg:space-x-7 space-y-7 lg:space-y-0 mt-7">
            <BlkContainer className='bg-1'>
              <DashStatPacks data={stats} />
            </BlkContainer>
            <BlkContainer className='bg-2'>
              <DashStatUnilevel data={stats} />
            </BlkContainer>
            <BlkContainer className='bg-4'>
              <DashStatReferrals data={stats} />
            </BlkContainer>
          </div>
          }
      </div>
    </>
  );
};
export default Dashboard;
