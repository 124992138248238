import { useNavigate } from "react-router-dom";
import i1 from "../../../Assets/i-1.png";
import i2 from "../../../Assets/i-2.png";
import i3 from "../../../Assets/i-3.png";
import i4 from "../../../Assets/i-4.png";
import i5 from "../../../Assets/i-5.png";
import i6 from "../../../Assets/i-6.png";
import i7 from "../../../Assets/i-7.png";
import i9 from "../../../Assets/products.png";
import MenuComponent from "./Menu";

export const NavDash = ({ onCloseDrawer = null }) => {

  const history = useNavigate();

  const navigate = (page) => {
    history(page);
    onCloseDrawer && onCloseDrawer();
  }

  return (
    <>
        <div onClick={() => navigate('/')}><MenuComponent icon={i1} text='Dashboard' /></div>
        <div onClick={() => navigate('/packs')}><MenuComponent icon={i2} text='My Pickaxes' /></div>
        <div onClick={() => navigate('/referrals')}><MenuComponent icon={i3} text='Mining Partners' /></div>
        <div onClick={() => navigate('/payments')}><MenuComponent icon={i4} text='My Payments' /></div>
        <div onClick={() => navigate('/activities')}><MenuComponent icon={i5} text='All Activity' /></div>
        <div onClick={() => navigate('/ebooks')}><MenuComponent icon={i9} text='Exclusive Products' /></div>
        <div onClick={() => navigate('/tgAlerts')}><MenuComponent icon={i6} text='Settings' /></div>
        <div onClick={() => navigate('/logout')}><MenuComponent icon={i7} text='Logout' /></div>
    </>
  );
};