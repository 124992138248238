import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Maintitle from "../../Components/dash/layout/MainTitle";
import CyclesTable from "../../Components/dash/packs/CyclesTable";
import PackItem from "../../Components/dash/packs/PackItem";
import PaymentsTable from "../../Components/dash/packs/PaymentsTable";
import { useCallAPI } from "../../Hooks/callAPI";
import { BlkContainer, EmptyRecords, LoadingData } from "../../Utility/Utility";

const PackDetails = () => {

    const callAPI = useCallAPI();
    const params = useParams();
    const navigate = useNavigate();

    const[pack, setPack] = useState(false);
    const[payments, setPayments] = useState(false);
    const[cycles, setCycles] = useState(false);

    useEffect(() => {
        getPackData();
    }, []);

    const getPackData = async () => {
        if(params.id === undefined) {
            toast.error('Invalid Pack ID');
        }

        const res = await callAPI("getPackData/" + params.id);      
        if(res.status && res.data) {
            res.data.info ? setPack(res.data.info) : setPack({});
            res.data.payments ? setPayments(res.data.payments) : setPayments([]);
            res.data.cycles ? setCycles(res.data.cycles) : setCycles([]);
        } else {
            setPack({});
            setPayments([]);
            setCycles([]);
        }
    }

    return (
        <>
            <Maintitle title={'VIEWING PACK #' + pack?.id} showStats={false} />
            {pack.expired === 'yes' &&
                <div className="bg-red-900 text-center rounded-xl border-1 border-black text-white text-md font-semibold px-5 py-2 mb-5">
                    Your Pickax has expired. <br/><span className="font-normal text-sm">Pickaxes expire when they complete 3 cycles. Buy more Pickaxes to earn more.</span>
                </div>
            }
            {pack &&
                <>
                    {pack.id !== null && 
                        <div className='space-y-12'>
                            <BlkContainer className='bg-4'>
                                <PackItem pack={pack} showButton={false} />
                            </BlkContainer>
                            <BlkContainer className='bg-4'>
                                <CyclesTable data={cycles} />
                            </BlkContainer>
                            <BlkContainer className='bg-4'>
                                <PaymentsTable data={payments} />
                            </BlkContainer>
                        </div>
                    }
                    {pack.id === null && 
                        <EmptyRecords title='Pickaxe not found' description='This Pickaxe is not purchased yet. Try with a different ID.' />
                    }
                </>
            }
            {pack === false &&
                <LoadingData />
            }
        </>
    )
}

export default PackDetails;