import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../../Contexts/authContext";
import { useWalletContext } from "../../../Contexts/walletContext";
import DashStatContent from "./DashStatContent";

const DashStatPacks = ({ data }) => {

  const auth = useUserAuth();
  const walletContext = useWalletContext();

  const [stats, setStats] = useState([]);

  useEffect(() => {
    if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
        getStats();
    }
  }, [auth, walletContext.walletConnected]);

  const getStats = () => {
    setStats([
      {
        title: 'Purchased Pickaxes',
        value:  data.userStats.totalPurchasedPacks,
      },
      {
        title: 'Active Pickaxes',
        value: data.userStats.totalActivePacks,
      },
      {
        title: 'Pickaxes Created by Cycle',
        value: data.userStats.totalPacksByCycle,
      },
      {
        title: 'Purchased Pickaxes Cycled',
        value: data.userStats.packsCycled,
      },
      {
        title: 'Leg Payments Received',
        value: '$' + data.userStats.legPaymentsReceived,
      },
      {
        title: 'Leg Payments Received Today',
        value: '$' + data.userStats.legPaymentsReceivedToday,
      }
    ]);
  }
  
  return (
    <DashStatContent stats={stats} title={'My Pickaxes Stats'} />
  );
};
export default DashStatPacks;
