import { Button, Chip } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from "../../Contexts/walletContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { useHelpers } from "../../Hooks/helpers";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../Utility/Utility";

const Referrals = () => {

    const auth = useUserAuth();
    const helper = useHelpers();
    const callAPI = useCallAPI();
    const walletContext = useWalletContext();

    const [data, setData] = useState([]);
    const [dataLoadFull, setDataLoadFull] = useState(false);

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
            getResults(0);
        }
    }, [auth, walletContext.walletConnected]);

    const getResults = async (offset) => {
        const res = await callAPI("getReferrals" + "/" + offset);
        if(res.data !== false) {
          setData([...data, ...res.data]);
        } else {
            toast.success("All Rows Loaded");
            setDataLoadFull(true);
        }
    }

    return (
        <>
            <div className='py-5 md:py-6'>
                <div className='mb-5'>
                    <TitlePrimary>Mining partners</TitlePrimary>
                </div>
                {data && data.length > 0 &&
                    <>
                        <div className='table-custom-scroller border-4 border-dark rounded-lg'>
                            <table className='table-custom font-khand w-full overflow-hidden'>
                                <thead>
                                    <tr className="bg-one shadow-lg text-primary text-left text-lg">
                                        <th className="font-normal py-4 px-5">Username &<br/>User ID</th>
                                        <th className="font-normal py-4 px-5">Registered Wallet</th>
                                        <th className="font-normal py-4 px-5">Registered Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data && data.length > 0 && data.map((e, i) => {
                                    return (
                                        <tr key={i} className={`odd:bg-darker`}>
                                            <td className="px-5 py-4">{e.username} <Chip className="inline ml-2" size="sm" color="amber" value={'#' + e.userId} /></td>
                                            <td className="px-5 py-4"><helper.trimAddress address={e.address} copy /></td>
                                            <td className="px-5 py-4">{helper.ProcessDate(e.registered)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        {!dataLoadFull && <Button color="dark" className="mt-4" onClick={() => getResults(data.length)}>Load More</Button>}
                    </>
                }
                {data && data.length === 0 &&
                    <EmptyRecords title='No Referrals Found' description='Your referrals will be listed here.' />
                }
                {data === false &&
                    <LoadingData />
                }
            </div>
            
        </>
    );
};

export default Referrals;
