import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@material-tailwind/react';
import { useWeb3Modal } from '@web3modal/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import btnConnect from "../../Assets/btn-connectwallet.png";
import btnCreateaccount from "../../Assets/btn-createaccount.png";
import lock from "../../Assets/lock.png";
import registerTtitle from "../../Assets/registerTtitle.png";
import { WelcomeBox } from '../../Components/auth/WelcomeBox';
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from '../../Contexts/walletContext';
import { useCallAPI } from '../../Hooks/callAPI';
import { useHelpers } from '../../Hooks/helpers';
import { PendingModal } from '../../Hooks/pendingModal';
import { usePollAPI } from '../../Hooks/pollAPI';
import { useReadContract } from '../../Hooks/readContract';
import { useWriteContract } from '../../Hooks/writeContract';
import appConfig from '../../Utility/Config/config';

const Register = ({ takeToHome = false }) => {

    const callAPI = useCallAPI();
    const history = useNavigate();
    const walletContext = useWalletContext();
    const authContext = useUserAuth();
    const params = useParams();
    const writeContract = useWriteContract();
    const readContract = useReadContract();
    const helpers = useHelpers();
    const pollResults = usePollAPI();
    const web3modal = useWeb3Modal();

    const [formError, setFormError] = useState({});
    const [blockDiv, setBlockDiv] = useState('');
    const [userBalance, setUserBalance] = useState(false);

    useEffect(() => {
        if(params.ref) {
            localStorage.setItem("referrer_username", params.ref);
            if(takeToHome) {
                toast.success("Referred by " + params.ref + ". Taking to Home.");
                takeToHome && window.open(appConfig.websiteLink, '_self');
            } else {
                toast.success("Referred by " + params.ref);
            }
            setValue("referrer_username", params.ref);

        } else {
            takeToHome && window.open(appConfig.websiteLink, '_self');
            if(localStorage.getItem("referrer_username") !== null && localStorage.getItem("referrer_username") !== undefined) {
                toast.success("Referred by " + localStorage.getItem("referrer_username"));
                setValue("referrer_username", localStorage.getItem("referrer_username"));
            } else {
                setValue("referrer_username", "admin");
            }
        }
    }, []);

    //Check for authentication
    useEffect(() => {
        if(authContext.appBootstrapped === true && authContext.loggedIn === true) {
            history('/dashboard');
        }
    }, [authContext]);

    //Get user balance
    useEffect(() => {
        if(walletContext.walletConnected === true) {
            (async () => {
                setUserBalance(await readContract.getUserBalance());
            })();
        }
    }, [walletContext.walletConnected]);

    const FormSchema = yup.object().shape({
        referrer_username: yup.string().max(20).label('Referrer Username')
    });

    const {
        getValues,
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid }
    } = useForm({ mode: 'onChange', resolver: yupResolver(FormSchema) })

    const getReferrerAddress = async () => {
        setBlockDiv('registerNow');
        let formData = getValues();
        formData.address = walletContext.userAddress;
        const res = await callAPI("getReferrerAddress", formData);
        setFormError({});
        if(res.data.state === 1) { //Success
            await writeContract.ContractWrite({ writeFn: "Register", inputData: [res.data.referrerAddress], successCallback: registerSuccessCB });
            setBlockDiv('');
        } else { //Handle error
            setFormError(res.data.formError);
            toast.error(res.data.error);
            setBlockDiv('');
            console.log(res.data);
        }
    }

    const registerSuccessCB = (hash) => {
        return new Promise(resolve => {
            (async () => {
                const apiToPoll = () => callAPI("checkChainTransaction/main/" + hash);
                const pollContinueCondition = (res) => res.data.state === "pending";
                const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
                resolve();
                if(pollRes.data.state == "success") {
                    //Show success popup
                    Swal.fire({
                        title: "Registration Complete!",
                        text: "Registration completed successfully. You can now login.",
                        icon: 'success',
                        customClass: {
                            confirmButton: 'bg-one rounded-lg shadow-md text-md text-white font-bold h-[40px] px-10 mb-5'
                        },
                        buttonsStyling: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                          history('/login/autoLogin');
                        }
                    });
                    await authContext.getAppBootstrap();
                } else if(pollRes.data.state == "failed") {
                    toast.error("Failed to register.");
                }
             })();
        });
    }
    
    const startRegistration = async () => {

        try {
            if(!walletContext.walletConnected) {
                await walletContext.connectWallet();
            }
            if(walletContext.walletConnected !== true) { throw 'Failed to connect to wallet'; }
            if(userBalance.int < 0.005) { throw 'Have atleast 0.005 BNB to cover gas cost'; }
            getReferrerAddress();
        } catch (error) {
            toast.error(error);
        }
    }
    
    return (
        <>
            <WelcomeBox layout='register' />
            <div className='bg-one lgn-blk relative rounded-2xl_next border-[4px] border-[#F9F293] px-4 py-6 md:py-12 md:px-14'>
                <div className='space-y-6 pt-2'>
                    <div className='h-[35px] lg:h-[45px] mb-7 lg:mb-10'>
                    <img
                        className='max-w-full max-h-full block mx-auto'
                        src={registerTtitle}
                        alt='title'
                    />
                    </div>
                    <div className='text-center'>
                    <p className='text-[#DFCA45] font-semibold text-xl md:text-[22px] mb-3'>
                        1. Connect BSC Wallet
                    </p>
                    {walletContext.walletConnected ? 
                        <Button onClick={web3modal.open} color='green'>Connected to : <helpers.trimAddress address={walletContext.userAddress} firstShow={5} secondShow={5}/></Button>
                    : 
                        <button onClick={walletContext.connectWallet} className='h-[120px] inline-block'>
                        <img
                            className='max-w-full max-h-full block'
                            src={btnConnect}
                            alt='btn'
                        />
                        </button>
                    }
                    </div>
                    {writeContract.modalShow ?
                        <PendingModal show={writeContract.modalShow} data={writeContract.modalData} />
                    :
                        <div className='text-center'>
                            <p className='text-[#DFCA45] font-semibold text-xl md:text-[22px] mb-3'>
                                2. Enter your Referrer
                            </p>
                            <form onSubmit={handleSubmit(startRegistration)}>
                                <input
                                    type="text" 
                                    {...register('referrer_username')}
                                    placeholder="Referrer Username"
                                    className="text-white placeholder-[#999] rounded-xl bg-transparent text-center max-w-[250px] py-4 w-full text-lg mb-3 border-2 border-primary focus:outline-none focus:border-green-400 transition duration-200 shadow-xl"
                                />
                                <button type="submit" disabled={!walletContext.walletConnected || !isValid || blockDiv === 'registerNow'}  className='h-[120px] inline-block disabled:opacity-50'>
                                    <img
                                        className='max-w-full max-h-full block'
                                        src={btnCreateaccount}
                                        alt='btn'
                                    />
                                </button>
                                {formError?.address && <p className="text-gray-200 bg-red-700 px-3 py-2 text-md mt-5 text-center rounded-lg">{formError?.address}</p>}
                                {errors.referrer_username?.message && <p className="text-gray-200 bg-red-700 px-3 py-2 text-md mt-5 text-center rounded-lg">{errors.referrer_username?.message}</p>}
                                {formError?.referrer_username && <p className="text-gray-200 bg-red-700 px-3 py-2 text-md mt-5 text-center rounded-lg">{formError?.referrer_username}</p>}
                            </form>
                        </div>
                    }
                </div>
                <img
                    className='absolute -left-10 -bottom-10 w-[60px] lg:w-[80px]'
                    src={lock}
                    alt='lock'
                />
            </div>
        </>
    );
};

export default Register;