//Import CSS
import Invalidchain from '../Components/others/InvalidChain';
import { useUserAuth } from '../Contexts/authContext';
import appConfig from '../Utility/Config/config';
import AuthLayout from "./AuthLayout";
import DashboardLayout from "./DashboardLayout";
import LayoutLoading from "./LayoutLoading";

export default function Layout() {  
    const userAuth = useUserAuth();
    return (
        <>
            <Invalidchain />
            {userAuth.appBootstrapped && userAuth.loggedIn === false && <AuthLayout />}
            {userAuth.appBootstrapped && userAuth.loggedIn === true && <DashboardLayout />}
            {!userAuth?.appBootstrapped && <LayoutLoading />}
            <div className="bg-gray-900 text-center py-4 px-5 text-sm text-primary">
                <div className="">&copy; Wordofmine.io | All Rights Reserved - v{appConfig.version}</div>
            </div>
        </>
    );
}