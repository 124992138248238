import packs from "../../../Assets/packsPurchased.png";
import treasurebox from "../../../Assets/tressurebox.png";
import { useUserAuth } from "../../../Contexts/authContext";
import { TitlePrimary } from "../../../Utility/Utility";

const Maintitle = ({ title, showStats = true }) => {
  const auth = useUserAuth();
  return (
      <div className='space-y-8 md:space-y-0 md:flex justify-between items-center mt-3 mb-7 md:mb-10'>
          <TitlePrimary>{title}</TitlePrimary>
          {showStats &&
            <div className='space-y-6 sm:space-y-0 sm:flex jusity-end items-center sm:space-x-16'>
                <Stats
                    value={'$' +  auth?.userBootstrap?.userData?.totalIncome}
                    label='My Chest Value'
                    icon={treasurebox}
                />
                <Stats
                    value={auth?.userBootstrap?.userData?.totalPacks}
                    label='Active Pickaxes'
                    icon={packs}
                />
            </div>
          }
      </div>
  );
}
export default Maintitle;

const Stats = ({ value, label, icon }) => {
  return (
    <div className='grid grid-cols-[75px_auto] gap-4 items-center'>
      <div>
        <img src={icon} alt='icon' />
      </div>
      <div>
        <strong className='block text-primary tracking-[3.05px] text-[34px] leading-none'>
          {value}
        </strong>
        <span className='block text-white tracking-[1.4px] font-medium text-base'>
          {label}
        </span>
      </div>
    </div>
  );
};