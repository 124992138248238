import React from "react";

const ShortHeaderInfo = ({ icon, title, value }) => {
  return (
    <div className='grid grid-cols-[104px_auto] gap-1 align-items-center items-center'>
      <img className='max-w-full block' src={icon} alt='icon' />
      <div>
        <span className='text-primary text-[20px] text-shadow-one tracking-[1.6px] font-semibold block leading-tight mb-[0px]'>
          {title}
        </span>
        <strong className='text-white font-khand text-[25px] text-shadow-one tracking-[.6px] block leading-tight'>
          {value}
        </strong>
      </div>
    </div>
  );
};

export default ShortHeaderInfo;
