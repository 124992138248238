import { useState } from 'react';
import { BounceLoader } from 'react-spinners';
import { useHelpers } from './helpers';
import gear from "../Assets/gear.png";

export const usePendingModal = () => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState('');

    const showModal = (data) => {
        setShow(true);
        setData(data);
    }

    const hideModal = () => {
        setShow(false);
    }

    return [show, data, showModal, hideModal];
}

export const PendingModal = ({ show, data }) => {

    const helpers = useHelpers();

    return (
        <div className='text-center'>
            <img src={gear} className={`w-[60px] mx-auto mb-5 mt-2 animate-spin`} />
            <h1 className="text-xl text-primary mb-1">Processing Transaction</h1>
            <p className="text-sm text-[#fff] max-w-[390px] mx-auto font-normal">
                This will take a minute or two. Please be patient.<br/>Txn Hash : <b><helpers.trimAddress address={data} copy /></b> 
            </p>
        </div>
    )
}