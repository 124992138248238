import { Chip } from "@material-tailwind/react";
import React from "react";
import { EmptyRecords, LoadingData, TitleSecondary } from "../../../Utility/Utility";

const UnilevelStats = ({ data }) => {

    return (
        <>
            <div className='p-5'>
                <TitleSecondary>My Unilevel Downlines</TitleSecondary>
            </div>
            {data && data.length > 0 &&
                <>
                    <div className='table-custom-scroller'>
                        <table className='font-khand w-full'>
                            <thead>
                                <tr className="bg-one shadow-lg text-primary text-left text-lg">
                                    <th className="font-normal py-4 px-5">level</th>
                                    <th className="font-normal text-center py-4 px-5">Downlines</th>
                                    <th className="font-normal text-center py-4 px-5">Earned</th>
                                </tr>
                            </thead>
                            <tbody>
                            {data && data.length > 0 && data.map((e, i) => {
                                return (
                                    <tr key={i} className="text-left">
                                        <td className="px-5 py-3"><Chip className="inline" value={'Level ' + (i*1+1)} /></td>
                                        <td className="px-5 py-3 font-bold text-center text-shadow-one text-lg text-white">{e.downlines}</td>
                                        <td className="px-5 py-3 font-bold text-center text-shadow-one text-lg text-primary">${e.payments*2}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </>
            }
            {data && data.length === 0 &&
                <EmptyRecords title='No Downlines Found' description='Purchase a pickaxe to view your downline' />
            }
            {data === false &&
                <EmptyRecords title='No Downlines Found' description='Purchase a pickaxe to view your downline' />
            }
        </>
    );
};

export default UnilevelStats;
