import { Button, Chip } from "@material-tailwind/react";
import parse from 'html-react-parser';
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from "../../Contexts/walletContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { useHelpers } from "../../Hooks/helpers";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../Utility/Utility";

const Activities = () => {

    const auth = useUserAuth();
    const helper = useHelpers();
    const callAPI = useCallAPI();
    const walletContext = useWalletContext();

    const [data, setData] = useState([]);
    const [dataLoadFull, setDataLoadFull] = useState(false);

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
            getResults(0);
        }
    }, [auth, walletContext.walletConnected]);

    const getResults = async (offset) => {
        const res = await callAPI("getActivities" + "/" + offset);
        if(res.data !== false) {
          setData([...data, ...res.data]);
            await callAPI("markActivitiesAsRead");
        } else {
            toast.success("All Rows Loaded");
            setDataLoadFull(true);
        }
    }

    return (
        <div className='py-5 md:py-6'>
        <div className='mb-5'>
                <TitlePrimary>Recent Activities</TitlePrimary>
            </div>
            {data && data.length > 0 &&
                <>
                    <div className='table-custom-scroller border-4 border-dark rounded-lg'>
                        <table className='table-custom font-khand w-full overflow-hidden'>
                            <thead>
                                <tr className="bg-one shadow-lg text-primary text-left text-lg">
                                    <th className="font-normal py-4 px-5">Activity</th>
                                    <th className="font-normal py-4 px-5">Description</th>
                                    <th className="font-normal py-4 px-5">Hash</th>
                                    <th className="font-normal py-4 px-5">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                            {data && data.length > 0 && data.map((e, i) => {
                                return (
                                    <tr key={i} className={`odd:bg-darker ${e.readOn === 'yes' ? 'bg-[#111] opacity-90' : 'bg-[#000]'}`}>
                                        <td className="px-5 py-4">{helper.capitalize(e.type)}</td>
                                        <td className="px-5 py-4">{parse(e.activity)}</td>
                                        <td className="px-5 py-4"><Chip className="inline" color='gray' value={helper.trimAddress({ address: e.hash, copy: true })} /></td>
                                        <td className="px-5 py-4">{helper.ProcessDate(e.created)}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                    {!dataLoadFull && <Button color="amber" className="mt-4" onClick={() => getResults(data.length)}>Load More</Button>}
                </>
            }
            {data && data.length === 0 &&
                <EmptyRecords title='No Activity Found' description='Your activities will be listed here.' />
            }
            {data === false &&
                <LoadingData />
            }
        </div>
    );
};

export default Activities;
