import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../../Contexts/authContext";
import { useWalletContext } from "../../../Contexts/walletContext";
import { useHelpers } from "../../../Hooks/helpers";
import { BlkContainer, TitleSecondary } from "../../../Utility/Utility";
import DashStatContent from "./DashStatContent";

const AccountDetails = () => {

  const auth = useUserAuth();
  const walletContext = useWalletContext();
  const helper = useHelpers();

  const [stats, setStats] = useState([]);

  useEffect(() => {
    if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
        getStats();
    }
  }, [auth, walletContext.walletConnected]);

  const getStats = () => {
    setStats([
      {
        title: 'Username',
        value:  auth?.userBootstrap?.userData?.username,
      },
      {
        title: 'Registered Wallet',
        value: <helper.trimAddress address={auth?.userBootstrap?.userData?.address} copy={true} />,
      },
      {
        title: 'Connected Wallet',
        value: <helper.trimAddress address={walletContext?.userAddress} copy={true} />,
      },
      {
        title: 'Mining Sponsor ID',
        value: '#' + auth?.userBootstrap?.userReferrerData?.id,
      },
      {
        title: 'Mining Sponsor Username',
        value: auth?.userBootstrap?.userReferrerData?.username,
      },
      {
        title: 'Mining Sponsor Wallet',
        value: <helper.trimAddress address={auth?.userBootstrap?.userReferrerData?.address} copy={true} />,
      },
    ]);
  }
  
  return (
    <DashStatContent title='My Account Details' stats={stats} />
  );
};
export default AccountDetails;
