import { Chip } from "@material-tailwind/react";
import React from "react";
import bnb from "../../../Assets/bnb.png";
import nullimg from "../../../Assets/nullImg.png";
import recycle from "../../../Assets/recycle.png";
import { EmptyRecords, LoadingData, TitleSecondary } from "../../../Utility/Utility";
import loader from "../../../Assets/loader.png";

const CyclesTable = ({ data }) => {

    return (
        <>
            <div className='p-5 md:p-6'>
                <div className='mb-5'>
                    <TitleSecondary>CYCLES</TitleSecondary>
                </div>
                {data && data.length > 0 &&
                    <>
                        <div className='table-custom-scroller'>
                            <table className='table-custom font-khand w-full'>
                                <thead>
                                    <tr className="bg-one shadow-lg text-primary text-left text-lg">
                                        <th className="rounded-l-lg font-normal py-4 px-5">Pack #</th>
                                        <th className="font-normal py-4 px-5">Left Leg #</th>
                                        <th className="rounded-r-lg font-normal py-4 px-5">Right Leg #</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data && data.length > 0 && data.map((e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className="px-5 py-4">{e.id} {e.isCycle == 'no' ? <Chip className="inline ml-3" color='green' value='Parent' /> : <Chip className="inline ml-3" color='indigo' value='Child' />}</td>
                                            <td className="px-5 py-4"><img src={bnb} className="w-7 inline mr-1" /> {e.leg1 !== null ? <>#{e.leg1}</> : <img src={nullimg} className="inline w-7 opacity-40" />}</td>
                                            <td className="px-5 py-4"><img src={loader} className="w-7 inline mr-1" /> {e.leg2 !== null ? <>#{e.leg2}</> : <img src={nullimg} className="inline w-7 opacity-40" />}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </>
                }
                {data && data.length === 0 &&
                    <EmptyRecords title='No Cycles Found' description='Your cycles will be listed here.' />
                }
                {data === false &&
                    <LoadingData />
                }
            </div>
        </>
    );
};

export default CyclesTable;
