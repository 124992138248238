import { Chip } from "@material-tailwind/react";
import React from "react";
import { useHelpers } from "../../../Hooks/helpers";
import { EmptyRecords, LoadingData, TitleSecondary } from "../../../Utility/Utility";

const PaymentsTable = ({ data }) => {

    const helper = useHelpers();
    
    return (
        <>

            <div className='p-5 md:p-6'>
                <div className='mb-5'>
                    <TitleSecondary>PAYMENTS RECEIVED</TitleSecondary>
                </div>
                {data && data.length > 0 &&
                    <>
                        <div className='table-custom-scroller'>
                            <table className='table-custom font-khand w-full'>
                                <thead>
                                    <tr className="bg-one  shadow-lg text-primary text-left text-lg">
                                        <th className="rounded-l-lg font-normal py-4 px-5">From User</th>
                                        <th className="font-normal py-4 px-5">Pack #</th>
                                        <th className="font-normal py-4 px-5">Amount</th>
                                        <th className="font-normal py-4 px-5">Type</th>
                                        <th className="font-normal py-4 px-5">Hash</th>
                                        <th className="rounded-r-lg font-normal py-4 px-5">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data && data.length > 0 && data.map((e, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className="px-5 py-4">{e.from_id*1 !== 0 ? <>ID {e.from_id}</> : <>System</>}</td>
                                            <td className="px-5 py-4">#{e.pack_id}</td>
                                            <td className="px-5 py-4"><helper.AmountToCurrency amount={e.amount} /></td>
                                            <td className="px-5 py-4"><Chip className="inline" color='amber' value={helper.capitalize(e.type)} /></td>
                                            <td className="px-5 py-4"><Chip className="inline" color='gray' value={helper.trimAddress({ address: e.hash, copy: true })} /></td>
                                            <td className="px-5 py-4">{helper.ProcessDate(e.created)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </>
                }
                {data && data.length === 0 &&
                    <EmptyRecords title='No Payments Found' description='Your payments will be listed here.' />
                }
                {data === false &&
                    <LoadingData />
                }
            </div>
        </>
    );
};

export default PaymentsTable;
