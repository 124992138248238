import React from "react";
import { Link } from "react-router-dom";
import btnMore from "../../../Assets/btn-more.png";
import loader from "../../../Assets/loader.png";
import totalIncome from "../../../Assets/totalIncome.png";
import { TitlePrimaryTwo } from "../../../Utility/Utility";
import Stats from "../dashboard/Stats";
import MemberTree from "./MemberTree";
import gear from "../../../Assets/gear.png";

const PackItem = ({ pack, showButton = true }) => {

  return (
    <div className='space-y-6 sm:space-y-0 sm:grid grid-cols-2 sm:gap-6 p-5 md:p-6 relative'>
      <img src={gear} className={`w-[100px] absolute -top-5 -right-5 ${pack.expired === 'yes' ? '' : 'animate-spin'} `} />
      <div className='space-y-4'>
        <div className='flex items-center mb-7 tertiary-ttile'>
          <TitlePrimaryTwo>Pickaxe #{pack.id}</TitlePrimaryTwo>
          {pack.expired === 'yes' ?
            <span className='inline-block ml-3 uppercase bg-red-600 rounded-lg text-white text-base pb-[4px] pt-[6px] tracking-[0.72px] font-bold px-4 '>
              EXPIRED
            </span>
          :
            <span className='inline-block ml-3 uppercase border-2 border-green-400 rounded-lg text-green-400 text-base pb-[4px] pt-[6px] tracking-[0.72px] font-bold px-4 '>
              ACTIVE
            </span>
          }
        </div>
        <div className='space-y-5'>
          <Stats value={'$' + (pack.totalEarned*1).toFixed(0)} label='Earned from Pickaxe' icon={totalIncome} />
          <Stats value={pack.totalCycles} label='Total Cycles' icon={loader} />
        </div>
        {showButton &&
          <Link to={'/packs/' + pack.id}>
            <button className='h-[70px] inline-block mt-7'>
              <img
                className='max-w-full max-h-full block'
                src={btnMore}
                alt='btn'
              />
            </button>
          </Link>
        }
      </div>
      <div className='text-center'>
        <MemberTree leg1={pack.leg1} leg2={pack.leg2} />
      </div>
    </div>
  );
};
export default PackItem;
