import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@material-tailwind/react';
import React, { useState } from "react";
import { useForm } from 'react-hook-form';
import { toast } from "react-hot-toast";
import * as Yup from 'yup';
import { useUserAuth } from "../../../Contexts/authContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { BlkContainer, FormBlock, FormInput } from "../../../Utility/Utility";

const SetUsername = () => {

  const auth = useUserAuth();
  const callAPI = useCallAPI();

  const [blockDiv, setBlockDiv] = useState('');
  const [formError, setFormError] = useState({});

  const defaultValues = {
      username: auth?.userBootstrap?.userData?.username
  }

  const validationSchema = Yup.object().shape({
      username: Yup.string()
          .required('Please enter a username')
          .min(5, "Please enter atleast 5 characters")
          .max(10, "Please keep it short to 10 characters"),
  });

  const {
      register, formState: { errors, isValid, isDirty }, handleSubmit, getValues, setValue, control, watch
  } = useForm({ defaultValues: defaultValues, mode: 'onChange', resolver: yupResolver(validationSchema) })

  const onSubmit = async () => {

      setBlockDiv('createBtn');

      let formData = getValues();

      const res = await callAPI("setUsername", formData);

      console.log(res);
      setFormError({});

      if(res.data.state === 1) { //Success
          setBlockDiv('');
          toast.success("Username set");
          await auth.getAppBootstrap();
        } else { //Handle error
          setBlockDiv('');
          setFormError(res.data.formError);
          toast.error(res.data.error);
          console.log(res.data);
      }

  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BlkContainer>
          <FormBlock heading="Change Your Username">
              <div className='my-5'>
                  <FormInput name='username' label="New Username" helper="Attention: Changing username will also change your invitation link." reg={register} formerror={formError} errors={errors} isdirty={isDirty ? 1 : 0} />
              </div>
              <Button color='amber' size='lg' type="submit" disabled={!isValid || !isDirty} loading={blockDiv === 'createBtn'? 1 : 0}>Update</Button>
          </FormBlock>
        </BlkContainer>
    </form>
    </>

  );
};

export default SetUsername;
