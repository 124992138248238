import { Button, Chip } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from "../../Contexts/walletContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { useHelpers } from "../../Hooks/helpers";
import { EmptyRecords, LoadingData, TitlePrimary, decideColor } from "../../Utility/Utility";

const Payments = () => {

    const auth = useUserAuth();
    const helper = useHelpers();
    const callAPI = useCallAPI();
    const walletContext = useWalletContext();

    const [data, setData] = useState([]);
    const [dataLoadFull, setDataLoadFull] = useState(false);

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
            getResults(0);
        }
    }, [auth, walletContext.walletConnected]);

    const getResults = async (offset) => {
        const res = await callAPI("getPayments" + "/" + offset);
        if(res.data !== false) {
          setData([...data, ...res.data]);
        } else {
            toast.success("All Rows Loaded");
            setDataLoadFull(true);
        }
    }

    return (
        <>
            <div className='py-5 md:py-6'>
                <div className='mb-5'>
                    <TitlePrimary>Payments</TitlePrimary>
                </div>
                {data && data.length > 0 &&
                    <>
                    <div className='table-custom-scroller border-4 border-dark rounded-lg'>
                        <table className='table-custom font-khand w-full overflow-hidden'>
                            <thead>
                                <tr className="bg-one shadow-lg text-primary text-left text-lg">
                                    <th className="font-normal py-4 px-5">From User</th>
                                    <th className="font-normal py-4 px-5">From Pickaxe #</th>
                                    <th className="font-normal py-4 px-5">Parent Pickaxe #</th>
                                    <th className="font-normal py-4 px-5">Amount</th>
                                    <th className="font-normal py-4 px-5">Type</th>
                                    <th className="font-normal py-4 px-5">Hash</th>
                                    <th className="font-normal py-4 px-5">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data && data.length > 0 && data.map((e, i) => {
                                    return (
                                        <tr key={i} className={`odd:bg-darker`}>
                                            <td className="px-5 py-4">{e.from_id*1 !== 0 ? <>ID {e.from_id}</> : <>System</>}</td>
                                            <td className="px-5 py-4">#{e.pack_id}</td>
                                            <td className="px-5 py-4">#{e.parent_pack}</td>
                                            <td className="px-5 py-4"><helper.AmountToCurrency amount={e.amount} /></td>
                                            <td className="px-5 py-4"><Chip className="inline" color={decideColor(e.type)} value={helper.capitalize(e.type)} /></td>
                                            <td className="px-5 py-4"><Chip className="inline" value={helper.trimAddress({ address: e.hash, copy: true })} /></td>
                                            <td className="px-5 py-4">{helper.ProcessDate(e.created)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        {!dataLoadFull && <Button color="amber" className="mt-4" onClick={() => getResults(data.length)}>Load More</Button>}
                    </>
                }
                {data && data.length === 0 &&
                    <EmptyRecords title='No Payments Found' description='Your payments will be shown here.' />
                }
                {data === false &&
                    <LoadingData />
                }
            </div>
            
        </>
    );
};

export default Payments;
