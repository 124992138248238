import React, { useEffect, useState } from "react";
import { useUserAuth } from "../../../Contexts/authContext";
import { useWalletContext } from "../../../Contexts/walletContext";
import DashStatContent from "./DashStatContent";

const DashStatUnilevel = ({ data }) => {

  const auth = useUserAuth();
  const walletContext = useWalletContext();

  const [stats, setStats] = useState([]);

  useEffect(() => {
    if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
        getStats();
    }
  }, [auth, walletContext.walletConnected]);

  const getStats = () => {
    setStats([
      {
        title: 'Total Mining Partners (10 Level)',
        value: data.userStats.totalUniDownlines,
      },
      {
        title: 'Unilevel Payments Received',
        value: '$' + data.userStats.unilevelpayments,
      },
      {
        title: 'Unilevel Payments Received Today',
        value: '$' + data.userStats.unilevelpaymentsToday,
      },
      {
        title: 'Unilevel Payments Missed',
        value: '$' + data.userStats.missedunilevel,
      },
      {
        title: 'Unilevel Payments Missed Today',
        value: '$' + data.userStats.missedunilevelToday,
      }
    ]);
  }
  
  return (
    <DashStatContent stats={stats} title={"My Unilevel Stats"} />
  );
};
export default DashStatUnilevel;
