export const TitlePrimary = ({ children }) => {
  return (
    <h2 className='text-white uppercase shadow-1 text-2xl xl:text-4xl translate-y-1 font-longshotRegular font-normal tracking-[3.1px]'>
      {children}
    </h2>
  );
};

export const TitlePrimaryTwo = ({ children }) => {
  return (
    <h2 className='text-primary shadow-1 text-2xl xl:text-4xl translate-y-1 font-longshotRegular font-normal tracking-[1.8px]'>
      {children}
    </h2>
  );
};

export const TitleSecondary = ({ children }) => {
  return (
    <h2 className='text-primary uppercase text-2xl translate-y-1 font-longshotRegular font-normal tracking-[1.5px]'>
      {children}
    </h2>
  );
};

export const Overlay = ({ overlay }) => {
  return (
    <div
      className={`${
        overlay ? "opacity-100 visible" : "opacity-0 invisible"
      } fixed w-screen h-screen top-0 left-0 right-0 bg-black/60 transition duration-300 z-40 `}
    ></div>
  );
};

export const BlkContainer = ({ children, className }) => {
  return <div className={`blk-container ${className}`}>{children}</div>;
};

import gear from "../Assets/gear.png";
export const LoadingData = ({ fullHeight = true }) => {
  return (
      <div className={`${fullHeight ? 'grid place-items-center py-[50px] sm:py-[100px]' : 'm-auto'}`}>
        <img src={gear} className={`w-[60px] mx-auto mb-5 mt-2 animate-spin`} />
      </div>
  )
}

import emptyImg from '../Assets/emptyImg.png';
export const EmptyRecords = ({ title, description, buttonHandler = false, fullHeight = false }) => {
  return (
    <div className={`grid place-items-center ${fullHeight ? 'h-screen' : 'py-[50px] sm:py-[100px]'}`}>
      <div className="max-w-[250px] text-center p-10 border-4 border-primary shadow-lg rounded-2xl">
        <img className="w-[80px] sm:w-[100px] block mx-auto my-2" src={emptyImg} />
        <p className="text-primary font-bold text-lg mx-3 leading-5 my-2 mt-5">{title}</p>
        <p className="text-gray-500 text-sm mx-5 leading-5 mt-1">{description}</p>
        {buttonHandler && <Button className="bg-gradient-gold px-5 py-2 rounded-[5px] font-bold text-sm mt-4" onClick={buttonHandler.onClick}>{buttonHandler.text}</Button>}
      </div>
    </div>
  )
}

//Form Components
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Button, Input } from "@material-tailwind/react";
import { AlertTriangle } from "react-feather";

export const FormBlock = ({ children, ...props }) => {
  return (
    <div {...props} className={`p-5`}>
      <div className="font-Bungee text-md text-primary mb-4">{props.heading}</div>
      {children}
    </div>
  );
};

export const FormLabel = ({ children, ...props }) => {
  return (
    <div className="">
      <div className="font-ibm-plex-sans text-sm font-semibold text-gray-300"><label {...props}>{children}</label></div>
      <p className=" text-gray-300/50 text-sm leading-[14px]">{props.helper}</p>
    </div>
  );
};

export const FormInput = ({ children, ...props }) => {
  return (
    <>
      <Input color='lime' className="text-gray-400" size='lg' {...props} {...props.reg(props.name)} icon={(props.isdirty && (props.errors[props.name]?.message || props.formerror[props.name])) ? <AlertTriangle size={16} className="text-red-400" /> : null} error={(props.isdirty && (props.errors[props.name]?.message || props.formerror[props.name])) ? true : false} />
      {(props.isdirty && (props.errors[props.name]?.message || props.formerror[props.name])) ? 
        <>
          {props.errors[props.name]?.message && <p className="text-red-400 text-xs leading-4 mt-2 text-left">{props.errors[props.name]?.message}</p>}
          {props.formerror[props.name] && <p className="text-red-400 text-xs leading-4 mt-2 text-left">{props.formerror[props.name]}</p>}
        </>
        :
        <p className="flex justify-start text-gray-300/50 text-xs leading-4 mt-2"><InformationCircleIcon width={12} className="mr-1"/> {props.helper}</p>
      }
    </>
  );
};

export const decideColor = (type) => {
  var color;

  switch (type) {
    case 'legPayment':
      color = 'amber';
      break;

    case 'fastStartBonus':
      color = 'green';
      break;

    case 'missedFastStart':
      color = 'red';
      break;
    
    case 'missedUnilevel':
      color = 'red';
      break;

    case 'unilevelPayment':
      color = 'indigo';
      break;

    default:
      color = 'gray'
      break;
  }
  return color;
};