import { NumericStepper } from '@anatoliygatt/numeric-stepper';
import { Button } from '@material-tailwind/react';
import React, { useEffect, useState } from "react";
import Countdown from 'react-countdown';
import { toast } from "react-hot-toast";
import { BarLoader } from 'react-spinners';
import Swal from "sweetalert2";
import b1 from "../../../Assets/b-1.png";
import b2 from "../../../Assets/b-2.png";
import b3 from "../../../Assets/b-3.png";
import btnPurchase from "../../../Assets/btn-purchase.png";
import coin from "../../../Assets/coin.png";
import { useUserAuth } from "../../../Contexts/authContext";
import { useWalletContext } from "../../../Contexts/walletContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { PendingModal } from "../../../Hooks/pendingModal";
import { usePollAPI } from "../../../Hooks/pollAPI";
import { useReadContract } from "../../../Hooks/readContract";
import { useWriteContract } from "../../../Hooks/writeContract";
import { TitlePrimaryTwo } from '../../../Utility/Utility';
import BlkSpent from './BlkSpent';

const PurchasePack = () => {

  const auth = useUserAuth();
  const walletContext = useWalletContext();
  const writeContract = useWriteContract();
  const readContract = useReadContract();
  const pollResults = usePollAPI();
  const callAPI = useCallAPI();

  const [userBalance, setUserBalance] = useState(false);
  const [blockDiv, setBlockDiv] = useState('');
  const [packCount, setPackCount] = useState(1);
  const [packCost, setPackCost] = useState(0);
  const [packCostBNB, setPackCostBNB] = useState(0);
  const [packLimit, setPackLimit] = useState(1);
  const [purchaseCooldown, setPurchaseCooldown] = useState(0);

  //Get user balance
  useEffect(() => {
    if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
        getBalances();
    }
  }, [auth, walletContext.walletConnected]);

  const getBalances = async () => {
    setUserBalance(await readContract.getUserBalance());
    const packCostRes = await readContract.read('PACK_COST');
    setPackCost(packCostRes.toString());
    const packCostBNBRes = await readContract.getCost(packCostRes.toString());
    setPackCostBNB(packCostBNBRes);
    const purchaseRules = await readContract.read('getPurchaseRulesData', [ walletContext.userAddress ] );
    setPurchaseCooldown(purchaseRules[0].toString()*1);
    setPackLimit(purchaseRules[1].toString()*1);
  }

  const startPurchase = async () => {
      setBlockDiv('purchaseBtn');
      // DO prelim checks
      try {

          if(!walletContext.walletConnected) {
              await walletContext.connectWallet();
          }
          if(walletContext.walletConnected !== true) { throw 'Failed to connect to wallet'; }
          if(walletContext.userAddress.toLowerCase() != auth.userBootstrap.userData.address.toLowerCase()) { throw 'Switch to your registered wallet address'; }

          //If Presale active allow only 1 purchase per wallet
          const launched = await readContract.read('LAUNCHED_ON');
          if(launched.toString()*1 === 0) {
            //Check if user is whitelisted
            const isWhiteListed = await readContract.read('whitelistedAddress', [ walletContext.userAddress ]);
            if(isWhiteListed) {
              //Check if 1 pack already bought. Show error
              if(auth?.userBootstrap.userTotalPacks*1 >= 1) {
                throw 'Only 1 pack allowed till Official launch'; 
              }
            } else {
              throw 'Please wait for Official Launch'; 
            }
          }

          //Limit maximium packsCount to limit set in contract
          if(packCount*1 > packLimit) {
            throw 'Cannot buy more than ' + packLimit + ' packs at once.'; 
          }

          const cost = await readContract.getCost(packCost*packCount);
          if(userBalance.int < cost.int) { throw 'Insufficient Balance'; }

          purchaseNow(cost);

      } catch (error) {
          console.log(error);
          toast.error(error);
          setBlockDiv('');
      }
  }

  const purchaseNow = async (cost) => {
      setBlockDiv('purchaseBtn');
      await writeContract.ContractWrite({ writeFn: "purchasePacks", inputData: [ packCount.toString() ], successCallback: purchaseCB, value: cost.bns});
      setBlockDiv('');
  }

  const purchaseCB = (hash) => {
      return new Promise(resolve => {
          (async () => {
              const apiToPoll = () => callAPI("checkChainTransaction/main/" + hash);
              const pollContinueCondition = (res) => res.data.state === "pending";
              const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
              resolve();
              if(pollRes.data.state == "success") {
                  //Show success popup
                  Swal.fire({
                      title: "Purchase Complete!",
                      text: "Purchase completed successfully.",
                      icon: 'success'
                  });
                  await auth.getAppBootstrap();
              } else if(pollRes.data.state == "failed") {
                  toast.error("Failed to purchase.");
              }
          })();
      });
  }

  return (
    <div className='p-5 md:p-8 relative rounded-[20px] box-shadow-[0px_10px_10px_0px_rgba(0,0,0,0.10)]'>
      <div className='grid grid-cols-1 lg:grid-cols-[380px_auto] gap-6 items-end'>
        <div className=''>
          <div className='flex items-center'>
            <TitlePrimaryTwo>BUY PICKAXES</TitlePrimaryTwo>
            <span className='inline-block ml-3 bg-[#393636] rounded-lg text-white text-base pb-[4px] pt-[6px] tracking-[0.72px] font-bold px-4 '>
              ${packCost/100} Per Pickaxe
            </span>
          </div>
          <div className='mb-6'>
            <h6 className='text-white tracking-[1.3px] font-medium text-lg my-5'>
              Choose how many Pickaxes to buy
            </h6>
            <div className='font-khand font-bold leading-10'>
            <NumericStepper
              minimumValue={1}
              stepValue={1}
              maximumValue={packLimit}
              initialValue={packCount}
              size='sm'
              inactiveTrackColor="#222222"
              activeTrackColor="#333333"
              activeButtonColor="#ffedd5"
              inactiveIconColor="#fb923c"
              hoverIconColor="#ea580c"
              activeIconColor="#9a3412"
              disabledIconColor="#fdba74"
              thumbColor="#fdba74"
              thumbLabelColor='#000'
              thumbShadowAnimationOnTrackHoverEnabled={true}
              focusRingColor="#FCDF8F"
              onChange={setPackCount}
            />
            </div>
          </div>
          {writeContract.modalShow ?
            <PendingModal show={writeContract.modalShow} data={writeContract.modalData} />
          :
            <>
              {packCostBNB.int > 0 ?
                <>
                  {purchaseCooldown > new Date() / 1000 ?
                      <Button className='red mb-10' color='amber' variant='outlined'>Purchase again in <Countdown date={purchaseCooldown*1000} onComplete={getBalances} /></Button>
                    :
                    <>
                      <button onClick={startPurchase} disabled={blockDiv === 'purchaseBtn' ? 1 : 0} className='h-[90px] disabled:opacity-50'>
                        <img
                          className='max-w-full block max-h-full'
                          src={btnPurchase}
                          alt='btn'
                        />
                      </button>
                      <p className="text-white text-md mt-2">
                        You will spend {(packCostBNB.int * packCount).toFixed(4)} BNB (${packCount * packCost / 100}) for this purchase
                      </p>
                    </>
                  }
                </>
              :
                <BarLoader
                  color={'#fff'}
                  className="mt-4"
                />
              }
            </>
          }
        </div>
        <div>
          <h3 className='text-primary text-3xl translate-y-1 font-longshotRegular font-normal tracking-[1.5px] mb-6'>
            How $65 is distributed?
          </h3>
          <div className='p-5 space-y-6 md:space-y-0 md:p-7 mt-6 flex justify-around flex-wrap rounded-2xl_next bg-[rgba(24,22,22,0.70)] box-shadow-[0px_11px_10px_0px_rgba(0,0,0,0.10)]'>
            <BlkSpent
              value='$15'
              label='Gold Rush bonus to your mining sponsor'
              icon={b1}
            />
            <BlkSpent
              value='$25'
              label='Enrolls you into 1x2 Global 3x Cycler'
              icon={b2}
            />
            <BlkSpent
              value='$25'
              label='Enrolls you into 10 UNI-LEVEL Personal Build.'
              icon={b3}
            />
          </div>
        </div>
      </div>
      <img
        className='absolute pack-trans-img hidden md:block'
        src={coin}
        alt='img'
      />
    </div>
    
  );
};

export default PurchasePack;