import React from "react";
import { TitlePrimary } from "../../Utility/Utility";
import Ebooks from "../../Components/dash/products/Ebooks";
import { Chip } from "@material-tailwind/react";

const EbooksProduct = () => {
    
    return (
        <>
            <div>
                <div className="mb-6 sm:flex justify-between items-center space-y-3 sm:space-y-0">
                    <TitlePrimary>Products - Ebooks</TitlePrimary>
                    <Chip color="indigo" value="Require atleast 1 Pickaxe to Download" />
                </div>
                <Ebooks />
            </div>
            
        </>
    );
};

export default EbooksProduct;
