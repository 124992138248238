import React from "react";
import bgDashboard from "../Assets/bg-dashboard.webp";
import HeaderDash from "../Components/dash/layout/HeaderDash";
import { NavDash } from "../Components/dash/layout/NavDash";
import AppRouter from "./AppRouter";
import { BlkContainer } from "../Utility/Utility";
import Stats from "../Components/dash/dashboard/Stats";
import iTotalExpense from "../Assets/total-expense.png";
import iTotalIncome from "../Assets/total-income.png";
import { FooterStats } from "../Components/dash/layout/FooterStats";
import appConfig from "../Utility/Config/config";
import { Button } from "@material-tailwind/react";
import { ArrowLeftCircleIcon, ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import ComingSoon from "../Components/dash/dashboard/ComingSoon";

const Dashboardlayout = () => {

  return (
    <div className='bg-[#0B002B] relative font-khand px-4 pb-4 md:p-6 pt-4'>
      <div
        className='w-full h-full absolute top-0 start-0 bottom-0 end-0 bg-cover bg-center z-0'
        style={{ backgroundImage: `url(${bgDashboard})`, backgroundPosition: 'center bottom', backgroundSize: 'cover', backgroundAttachment: 'fixed' }}
      ></div>
      <div className='mb-[11px]'>
        <HeaderDash />
      </div>
      <main className='overflow-hidden relative px-4 py-6 md:p-6 md:pb-10 bg-one border-[4px] sm:border-[#E9E38B] border-dark rounded-2xl drop-shadow-[0px_19px_20px_rgba(0,0,0,0.30)]'>
        <div className='xl:grid xl:grid-cols-[250px_auto] gap-7 relative z-10'>
          <div className='space-y-8 hidden xl:block'>
            <a href={appConfig.complan} target="blank"><Button className="bg-primary text-xl text-dark font-khand" color="green" size="md">View Complan</Button></a>
            <a href={appConfig.complanVideo} target="blank"><Button className="bg-red-500 mt-2 text-xl text-[#fff] font-khand" color="green" size="md">Watch Video</Button></a>
            <ul>
              <NavDash />
            </ul>
          </div>
          <div>
            {/* <ComingSoon /> */}
            <a href={appConfig.id1BuyAlertGroup} target="blank">
              <div className="text-center p-2 font-semibold text-lg bg-primary animate-pulse shadow-lg uppercase rounded-xl mb-10 flex align-middle items-center justify-center">
                <ArrowLeftCircleIcon className="w-10 mr-4" />
                View ID 1's Auto Buys in Telegram realtime
                <ArrowRightCircleIcon className="w-10 ml-5" /> 
              </div>
            </a>
            <AppRouter />
          </div>
        </div>
      </main>
      <FooterStats />
    </div>
  );
};

export default Dashboardlayout;
