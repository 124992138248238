import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Copy } from "react-feather";
import toast from "react-hot-toast";

const CopyLink = ({ title, link }) => {
  return (
      <CopyToClipboard onCopy={() => toast.success('Link Copied!')} text={link}>
        <div className='space-y-1'>
          <h4 className='text-primary text-[22px] font-khand font-bold'>{title}</h4>
          <div className='text-white text-sm sm:text-lg font-medium flex items-center align-middle justify-center'>
            {link} <Copy size={14} className="ml-1 text-primary" />
          </div>
        </div>
      </CopyToClipboard>
  );
};

export default CopyLink;
