import React from "react";
import { TitleSecondary } from "../../../Utility/Utility";

const DashStatContent = ({ stats, title }) => {

  return (
    <>
      <div className='p-5'>
          <TitleSecondary>{title}</TitleSecondary>
      </div>
      <div>
        <ul>
          {stats.map((row, index) => {
            return (
              <li
                key={index}
                className="odd:bg-one grid sm:grid-cols-[80%_20%] items-center sm:space-x-4 text-white tracking-[.6px] font-semibold text-base px-6 py-4"
              >
                <span className="block font-normal text-md">{row.title}</span>
                <span className="block font-bold sm:text-right text-md sm:text-lg text-primary">{row.value}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
export default DashStatContent;
