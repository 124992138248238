import React from "react";
import { useWalletContext } from "../../Contexts/walletContext";
const Invalidchain = () => {

    const walletContext = useWalletContext();

    return (
        <>
            {!walletContext?.chainId &&
                <div className="relative z-10 bg-red-800 text-center px-4 py-4 text-white font-semibold text-sm">
                    Invalid Chain or wallet not connected.
                </div>
            }
        </>
    );
};

export default Invalidchain;
