import React from "react";
import { NavDash } from "./NavDash";
import UserDetailsDash from "./UserDetailsDash";
import { Button } from "@material-tailwind/react";
import appConfig from "../../../Utility/Config/config";

const Drawer = ({ onCloseDrawer }) => {
  return (
    <div className='drawer'>
      <button onClick={onCloseDrawer} className='text-white mb-6'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='30'
          height='30'
          fill='currentColor'
          className='bi bi-x-lg'
          viewBox='0 0 16 16'
        >
          <path d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z' />
        </svg>
      </button>

      <div className='space-y-5 mb-4 -ml-3'>
        <UserDetailsDash />
      </div>

      <div className='space-y-7 mt-7'>
        <a href={appConfig.complan} target="blank"><Button className="bg-primary text-xl text-dark font-khand" color="green" size="md">View Complan</Button></a>
        <ul>
          <NavDash onCloseDrawer={onCloseDrawer} />
        </ul>
      </div>
    </div>
  );
};

export default Drawer;
