import React from "react";
import loader from "../../../Assets/loader.png";
import memberImg from "../../../Assets/member-2.png";
import nullImg from "../../../Assets/nullImg.png";
import triangleShape from "../../../Assets/triangle-shape.png";
import SingleMemberCommon from "./SingleMemberCommon";
import SingleMemberTop from "./SingleMemberTop";

const MemberTree = ({ leg1, leg2 }) => {
  return (
    <div className='text-center memberTree max-w-[180px] ml-auto mr-10 space-y-4'>
      <SingleMemberTop label='You' profile={memberImg} />
      <div className='relative triangle-outer mx-auto'>
        <img className='w-full' src={triangleShape} alt='shape' />
      </div>
      <div className='flex justify-between'>
        {leg1 > 0 ?
          <SingleMemberCommon label={'ID ' + leg1} profile={memberImg} value='$25 BNB' />
        :
          <SingleMemberCommon label='EMPTY' profile={nullImg} value='$25 BNB' />
        }
        {leg2 > 0 ?
          <SingleMemberCommon label={'ID ' + leg2} profile={loader} value='CYCLED' color='blue' />
        :
          <SingleMemberCommon label='EMPTY' profile={nullImg} value='' color='blue' />
        }
      </div>
    </div>
  );
};

export default MemberTree;
