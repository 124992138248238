import React, { useEffect, useState } from "react";
import Maintitle from "../../Components/dash/layout/MainTitle";
import PackItem from "../../Components/dash/packs/PackItem";
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from "../../Contexts/walletContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { BlkContainer, EmptyRecords, LoadingData } from "../../Utility/Utility";

const Packs = () => {

    const auth = useUserAuth();
    const callAPI = useCallAPI();
    const walletContext = useWalletContext();

    const [packs, setPacks] = useState(false);

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
            getResults();
        }
    }, [auth, walletContext.walletConnected]);

    const getResults = async () => {        
        const res = await callAPI("getPacks");     
        if(res.status && res.data) {
            setPacks(res.data);
        } else {
            setPacks([]);
        }
    }

    return (
        <>
            <Maintitle title='MY Pickaxes' />
            {packs && packs.length > 0 &&
                <div className='space-y-12'>
                    <div className='space-y-5 md:space-y-0 md:grid grid-cols-2 gap-5 xl:gap-6'>
                        {packs && packs.length > 0 && packs.map((e, i) => {
                            return (
                                <BlkContainer className='bg-4' key={i}>
                                    <PackItem pack={e} showButton={true} />
                                </BlkContainer>
                            )
                        })}
                    </div>
                </div>
            }
            {packs && packs.length === 0 &&
                <EmptyRecords title='No Pickaxes Found' description='Purchased Pickaxes will be listed here.' />
            }
            {packs === false &&
                <LoadingData />
            }
        </>
    );
};

export default Packs;
